import { useEffect, useMemo } from 'react'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createStore } from './store'
import Theme from './theme'
import router from './routes'
import Notifications from './components/Notifications'

const queryClient = new QueryClient()

const App = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const store = useMemo(() => createStore(), [])

  return (
    <div className="App">
      <Provider store={store}>
        <Theme>
          <Notifications>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
            </QueryClientProvider>
          </Notifications>
        </Theme>
      </Provider>
    </div>
  )
}

export default App
