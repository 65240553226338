import SimpleBar from 'components/SimpleBar'
import NavUser from './NavUser'
import Navigation from './Navigation'

const Content = () => (
  <>
    <SimpleBar sx={{ '& .simplebar-content': { display: 'flex', flexDirection: 'column' } }}>
      <Navigation />
    </SimpleBar>
    <NavUser />
  </>
)

export default Content
