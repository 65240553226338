import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { apiCallBegan } from './api'

const slice = createSlice({
  name: 'predicts',
  initialState: {
    loading: false,
  },
  reducers: {
    uploadRequested: state => {
      state.loading = true
      state.error = null
    },
    uploadRequestEnded: state => {
      state.loading = false
    },
  },
})

export const {
  uploadRequested,
  uploadRequestEnded,
  resetUploadState,
} = slice.actions

export default slice.reducer

// API call
export const uploadArchive = file => {
  const formData = new FormData()
  formData.append('file', file)

  return apiCallBegan({
    url: '/predicts/upload',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onStart: uploadRequested,
    onEnd: uploadRequestEnded,
  })
}

// Selector
export const selectPredicts = createSelector(
  state => state.predicts,
  predicts => predicts,
)
