import { TransmitSquare, User } from 'iconsax-react'

export const MENU = [
  {
    id: 'access',
    title: 'Access',
    type: 'group',
    children: [
      {
        id: 'users',
        title: 'Users',
        type: 'item',
        url: '/users',
        icon: User,
        target: false,
      },
      {
        id: 'predicts',
        title: 'Predicts',
        type: 'item',
        url: '/predicts',
        icon: TransmitSquare,
        target: false,
      },
    ],
  },
]
