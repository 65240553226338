import { useCallback } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { Sun1, Moon } from 'iconsax-react'
import { ThemeMode } from 'config'
import useConfig from 'hooks/useConfig'
import IconButton from 'components/@extended/IconButton'

const ToggleTheme = () => {
  const theme = useTheme()
  const { changeMode } = useConfig()

  const isDarkMode = theme.palette.mode === ThemeMode.DARK

  const iconBackColor = isDarkMode ? 'background.default' : 'secondary.100'

  const handleToggle = useCallback(() => {
    changeMode(isDarkMode ? ThemeMode.LIGHT : ThemeMode.DARK)
  }, [changeMode, isDarkMode])

  return (
    <Box sx={{ flexShrink: 0, ml: 0.5 }}>
      <IconButton
        color="secondary"
        variant="light"
        aria-label="toggle theme"
        aria-haspopup="true"
        onClick={handleToggle}
        size="large"
        sx={{ color: 'secondary.main', bgcolor: iconBackColor, p: 1 }}
      >
        {isDarkMode ? <Sun1 variant="Bold" /> : <Moon variant="Bold" />}
      </IconButton>
    </Box>
  )
}

export default ToggleTheme
