import { useCallback, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { ArrowRight2 } from 'iconsax-react'
import { logout } from 'store/auth'
import useAuth from 'hooks/useAuth'
import avatar from 'assets/images/users/avatar-6.png'
import Avatar from 'components/@extended/Avatar'
import { Context as LayoutContext } from 'layout/Provider'
import * as Styled from './index.styles'

const NavUser = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { drawerOpen } = useContext(LayoutContext)
  const { user } = useAuth()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleLogout = useCallback(async () => {
    try {
      await dispatch(logout())
      navigate(`/login`, { state: { from: '' } })
    } catch (err) {
      console.error(err)
    }
  }, [dispatch, navigate])

  const open = Boolean(anchorEl)

  const expand = useCallback(event => {
    setAnchorEl(event.currentTarget)
  }, [])

  const close = useCallback(() => {
    setAnchorEl(null)
  }, [])

  // noinspection JSValidateTypes
  return (
    <Box
      sx={{
        p: 1.25,
        px: !drawerOpen ? 1.25 : 3,
        borderTop: '2px solid ',
        borderTopColor: 'divider',
      }}
    >
      <List disablePadding>
        <ListItem
          disablePadding
          secondaryAction={
            <Styled.ExpandMore
              expand={open}
              drawerOpen={drawerOpen}
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={expand}
              aria-label="show more"
            >
              <ArrowRight2 style={{ fontSize: '0.625rem' }} />
            </Styled.ExpandMore>
          }
          sx={{
            ...(!drawerOpen && { display: 'flex', justifyContent: 'flex-end' }),
            '& .MuiListItemSecondaryAction-root': { right: !drawerOpen ? 16 : -16 },
          }}
        >
          <ListItemAvatar>
            <Avatar
              alt="Avatar"
              src={avatar}
              sx={{ ...(drawerOpen && { width: 46, height: 46 }) }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={user?.name}
            sx={{ ...(!drawerOpen && { display: 'none' }) }}
            secondary={user?.username}
          />
        </ListItem>
      </List>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={close}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
        <MenuItem component={Link} to="#" onClick={close}>
          Profile
        </MenuItem>
        <MenuItem component={Link} to="#" onClick={close}>
          My account
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default NavUser
