import { combineReducers, configureStore } from '@reduxjs/toolkit'
import api from './middleware/api'
import apiError from './middleware/apiError'
import auth from './auth'
import predicts from './predicts'

export const createStore = () =>
  configureStore({
    reducer: combineReducers({
      auth,
      predicts,
    }),
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({ serializableCheck: false }),
      api,
      apiError,
    ],
  })
