import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Search from './Search'
// import Profile from './Profile'
import Notification from './Notification'
import ToggleTheme from './ToggleTheme'
// import MobileSection from './MobileSection'

const Content = () => {
  const downLG = useMediaQuery(theme => theme.breakpoints.down('lg'))

  return (
    <>
      {!downLG && <Search />}
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}

      <Notification />
      <ToggleTheme />
      {/*{!downLG && <Profile />}*/}
      {/*{downLG && <MobileSection />}*/}
    </>
  )
}

export default Content
