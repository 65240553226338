import { Suspense } from 'react'
import Loader from 'components/Loader'

const loadable = Component => props => (
  <Suspense fallback={<Loader />}>
    <Component {...props} />
  </Suspense>
)

export default loadable
