import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import loadable from 'utils/loadable'
import GuestGuard from 'guards/GuestGuard'
import AuthGuard from 'guards/AuthGuard'
import Layout from 'layout'

const LoginPage = loadable(lazy(() => import('pages/Login')))
const UsersPage = loadable(lazy(() => import('pages/Users')))
const PredictsPage = loadable(lazy(() => import('pages/Predicts')))

const router = createBrowserRouter([
  {
    path: '/login',
    element: (
      <GuestGuard>
        <LoginPage />
      </GuestGuard>
    ),
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      { path: '/users', element: <UsersPage /> },
      { path: '/predicts', element: <PredictsPage /> },
    ],
  },
])

export default router
